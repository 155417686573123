// c.f. https://github.com/panva/jose/blob/e8cf88a4555385ea6ee41a2015f869888649caa2/src/runtime/browser/base64url.ts
import { base16 } from '@scure/base';
// https://web.dev/articles/base64-encoding#btoa_and_atob_with_unicode
export function arrayToBase64(bytes) {
    const binString = String.fromCodePoint(...bytes);
    return btoa(binString).replaceAll('=', '');
}
// https://web.dev/articles/base64-encoding#btoa_and_atob_with_unicode
export function base64ToArray(base64) {
    const binString = atob(base64);
    return Uint8Array.from(binString, (m) => m.codePointAt(0));
}
export function toBase64URL(base64) {
    return base64.replaceAll('+', '-').replaceAll('/', '_');
}
export function fromBase64URL(base64url) {
    return base64url.replaceAll('-', '+').replaceAll('_', '/');
}
export function arrayToBase64url(bytes) {
    return toBase64URL(arrayToBase64(bytes));
}
export function base64urlToArray(base64) {
    return base64ToArray(fromBase64URL(base64));
}
export const epochLength = 6;
export const randomLength = 10;
export const idLength = epochLength + randomLength;
// uses big endian
export function prefixEpochToArray(epoch, id) {
    for (let i = 0; i < epochLength; i++) {
        const divisor = Math.pow(256, epochLength - 1 - i);
        id[i] = Math.floor(epoch / divisor) % 256;
    }
}
export function idToEpoch(id) {
    let epoch = 0;
    for (let i = 0; i < epochLength; i++) {
        epoch = epoch * 256 + id[i];
    }
    return epoch;
}
export function incrementRandom(arr) {
    for (let i = idLength - 1; i >= epochLength; i--) {
        if (arr[i] < 255) {
            arr[i]++;
            return;
        }
        arr[i] = 0;
    }
    throw new Error('max random reached');
}
// inspired by https://github.com/ryan-mars/ulid-workers/blob/06689d9ddd894bfc608c6131f9bbd2a8b48bcbc8/src/ulid.ts#L147
export function idFactory() {
    let lastTime = -1;
    const lastId = new Uint8Array(idLength);
    return () => {
        const epochMs = Date.now();
        if (epochMs > lastTime) {
            lastTime = epochMs;
            crypto.getRandomValues(lastId);
            prefixEpochToArray(epochMs, lastId);
            return new Uint8Array(lastId);
        }
        else {
            incrementRandom(lastId);
            return new Uint8Array(lastId);
        }
    };
}
// separate from idFactory because it's only used by tests and adds unnecessary complexity/conditionals to idFactory
export const rawIdWithTime = (epochMs) => {
    const id = new Uint8Array(idLength);
    crypto.getRandomValues(id);
    prefixEpochToArray(epochMs, id);
    return id;
};
export let rawId = idFactory();
export function hexId() {
    return base16.encode(rawId());
}
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
export function base64urlId() {
    return arrayToBase64url(rawId());
}
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
export function base64urlIdWithTime(epochMs) {
    return arrayToBase64url(rawIdWithTime(epochMs));
}
const _binary = 
// doesn't actually error when built by `app`
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore don't look for `process` in the browser
typeof window === 'undefined' && process.env.NODE_ENV === 'test'
    ? {
        setRawId: (newRawId) => {
            rawId = newRawId;
        },
    }
    : undefined;
export { _binary };
