// We do some questionable shit with Brands, e.g. `"templateId" & "base64url"`.
// This allows us to pass Ids into functions that expect a generic base64url.
// However, this means we don't use Zod brands,`which leads to issues like
// grep E7F24704-8D0B-460A-BF2C-A97344C535E0
// In particular, the fix https://github.com/colinhacks/zod/pull/2097" doesn't work for us
export const userSettingId = ''; // if this changes, also update 5A7C82EC-D401-4250-896C-DDB0FBE9107E
export function cast(value) {
    // Do NOT cast from LocalId to RemoteId! This may compromise security - do not trust clients!
    // Only RemoteId to LocalId is valid!
    return value;
}
export function toLDbId(value) {
    return value;
}
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
export function fromLDbId(value) {
    return value;
}
