import { base64urlToArray, idToEpoch } from './binary.js';
// https://basarat.gitbook.io/typescript/type-system/discriminated-unions#throw-in-exhaustive-checks
export function assertNever(x, msg) {
    if (msg != null) {
        throw new Error(msg);
    }
    throw new Error(`Expected 'never', but got an unexpected value:
${JSON.stringify(x)}`);
}
export function undefinedMap(t, f) {
    if (t === undefined) {
        return t;
    }
    return f(t);
}
export function nullMap(t, f) {
    if (t === null) {
        return t;
    }
    return f(t);
}
// https://stackoverflow.com/a/65666402
// If you update this function, also update `./utility.workerd.ts`!
export function throwExp(error, ...metadata) {
    console.error('`throwExp` hit!');
    // eslint-disable-next-line no-debugger
    debugger;
    if (error == null) {
        console.trace();
        throw new Error('This error should never occur - please open an issue if you see this!');
    }
    if (metadata.length !== 0)
        console.error(...metadata);
    if (typeof error === 'string') {
        throw new Error(error);
    }
    throw error;
}
// https://stackoverflow.com/a/46700791/
export function notEmpty(value) {
    return value !== null && value !== undefined;
}
// highTODO property test
export function stringifyMap(map) {
    return JSON.stringify(Object.fromEntries(map));
}
export function parseMap(rawMap) {
    const parsed = JSON.parse(rawMap);
    const entries = Object.entries(parsed);
    return new Map(entries);
}
// highTODO property test
export function stringifySet(set) {
    return JSON.stringify([...set]);
}
export function parseSet(rawSet) {
    const parsed = JSON.parse(rawSet);
    return new Set(parsed);
}
export const objKeys = Object.keys;
export const objValues = Object.values;
export const objEntries = Object.entries;
// https://stackoverflow.com/a/6969486
export function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}
export const dayInMs = 86_400_000;
// https://gist.github.com/72lions/4528834
export function concat(a1, a2) {
    const tmp = new Uint8Array(a1.byteLength + a2.byteLength);
    tmp.set(a1, 0);
    tmp.set(new Uint8Array(a2), a1.byteLength);
    return tmp;
}
export function concatAB(a1, a2) {
    const tmp = new Uint8Array(a1.byteLength + a2.byteLength);
    tmp.set(new Uint8Array(a1), 0);
    tmp.set(new Uint8Array(a2), a1.byteLength);
    return tmp;
}
export async function sleep(ms) {
    await new Promise((resolve) => setTimeout(resolve, ms));
}
const timeoutSymbol = Symbol();
// https://stackoverflow.com/a/67630893
async function retry(f, count) {
    for (let attempt = 1; attempt <= count; attempt++) {
        try {
            return await f();
        }
        catch (err) {
            if (typeof err === 'object' &&
                err != null &&
                'cause' in err &&
                err.cause === timeoutSymbol) {
                console.debug(`Retry ${attempt}/${count}`);
            }
        }
    }
    throw Error(`Failed after ${count} tries`);
}
async function timeout(f, ms) {
    return await Promise.race([
        f(),
        sleep(ms).then(() => {
            throw Error(undefined, { cause: timeoutSymbol });
        }),
    ]);
}
export async function retryWithTimeout(f, count, timeoutMs) {
    return await retry(async () => await timeout(f, timeoutMs), count);
}
export function epochToDate(epoch) {
    return new Date(epoch * 1000);
}
export function idToDate(id) {
    return new Date(idToEpoch(base64urlToArray(id)));
}
export function rawIdToDate(id) {
    return new Date(idToEpoch(id));
}
export function dateToEpoch(date) {
    return date.getTime() / 1000;
}
export function maybeDateToEpoch(date) {
    if (date == null)
        return null;
    return dateToEpoch(date);
}
export function maybeEpochToDate(epoch) {
    if (epoch == null)
        return null;
    return epochToDate(epoch);
}
